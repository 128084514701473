<template>
  <Popup
    size="lg"
    modal-id="bundle-module-remark__modal-id"
    custom-class="gray-header"
    :onHide="close"
    :on-close="close"
    :scrollable="true"
    :toggle-status="showPopup"
  >
    <template v-slot:header>
      <div class="bundle-module-remark__header">
        <a href="#"> {{ labels.bookmark }}: {{ bookmarkTitle }} </a>
      </div>
    </template>

    <template v-slot:body>
      <FormulateForm
        class="bundle-module-remark__form"
        id="bundle-module-remark-form"
        @submit="submitData"
      >
        <FormulateInput
          v-model="values.text"
          type="textarea"
          id="formulate-input-textarea"
          name="noteContent"
          :label="labels.description"
          error-behavior="live"
        ></FormulateInput>
      </FormulateForm>
    </template>

    <template v-slot:footer>
      <div class="bundle-module-remark__footer">
        <Button
          class="footer-cancel"
          variant="btn-outline-purple"
          :action="close"
        >
          {{ labels.close }}
        </Button>

        <Button
          :label="labels.update"
          variant="btn-purple"
          data-dismiss="modal"
          style="fontweight: 600"
          :action="submitData"
        />
      </div>
    </template>
  </Popup>
</template>

<script>
import Popup from "@/components/Popups/Popup";
import Button from "@/components/Buttons/Button";

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "BundleModuleRemark",
  components: {
    Popup,
    Button,
  },
  data() {
    return {
      values: {
        text: "",
      },
      showPopup: true,
    };
  },
  methods: {
    ...mapActions("account", ["updateBookmark", "getBookmark"]),
    ...mapActions("notification", ["addNotification"]),
    submitData() {
      this.updateBookmark({
        ...this.values,
        id: this.$route.params.bookmark_id,
      })
        .then((res) => {
          if (res.error) {
            this.addNotification({ variant: "danger", msg: res.error });
          }
        })
        .catch((err) => console.log(err));

      this.openParentPage();
    },
    close() {
      this.showPopup = false;
      this.text = "";

      this.openParentPage();
    },
    loadBookmark() {
      this.getBookmark(this.$route.params.bookmark_id)
        .then((res) => {
          if (res.error) {
            this.addNotification({ variant: "danger", msg: res.error });
          }
        })
        .catch((err) => console.log(err));
    },
    openParentPage() {
      this.$router.push({
        name: "bookmarks",
      });
    },
  },
  mounted() {
    this.loadBookmark();
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
    ...mapState("account", ["bookmark"]),
    bookmarkTitle() {
      if (this.bookmark?.module?.title?.length)
        return this.bookmark.module.title;

      return "";
    },
  },
  watch: {
    bookmark(newValue) {
      if (newValue.text) this.values.text = newValue.text;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
::v-deep {
  .formulate-input {
    .formulate-input-element {
      max-width: 100% !important;
    }
    .formulate-input-label {
      font-weight: 700;
      margin-bottom: 15px;
    }
  }
  #formulate-input-textarea {
    border-color: $dark_2;
    min-height: 100px;
  }
}
.bundle-module-remark__header {
  font-weight: 800;
  a {
    text-decoration: none;
    color: #2c3e50;
  }
}
.bundle-module-remark__footer {
  display: flex;
  align-content: center;
  .footer-submit {
    margin-bottom: 0 !important;
  }
  ::v-deep .formulate-input {
    margin-bottom: 0px !important;

    .formulate-input-element {
      button {
        height: 45px;
      }
    }
  }
  .footer-cancel {
    margin-right: 15px;
    font-weight: 600;
  }
}
</style>
